<script>
import { SuawMainContent, SuawDivider, SuawArticleCard, SuawGridSection, SuawPageHeaderActions, SuawSubheaderTwo } from "@/components";
import * as db from "../data.js";
import WriteWithUs from "../../resources/components/WriteWithUs.vue";
export default {
  name: "ArticleDiscovery",
  metaInfo: {
    title: "Articles - ",
    meta: [
      { vmid: "description", name: "description", content: "Feel inspired, learn something new, or find some help. Our articles have something to keep everyone writing." }
    ]
  },
  components: {
    SuawMainContent,
    SuawPageHeaderActions,
    SuawDivider,
    SuawGridSection,
    SuawArticleCard,
    SuawSubheaderTwo,
    WriteWithUs
  },
  data() {
    return {
      cards: db.articles()
    };
  },
  computed: {
    featuredArticles() {
      return this.cards.filter(card => card.featured).map(card => ({ ...card }));
    },
    recentArticles() {
      return this.cards.map(card => ({ ...card, cardType: "CardArticle" }));
    }
  },
  methods: {
    onClicked(val) {
      this.$router.push(val.route);
    }
  }
};
</script>

<template>
  <SuawMainContent :show-gradient="true" size="large-small-gap">
    <SuawPageHeaderActions back-text="Back to Resources" :back-route-object="{ name: 'ResourceDiscovery' }" />
    <SuawDivider />
    <SuawSubheaderTwo
      class="article-discovery-subheader"
      title="Articles"
      description="Feel inspired, learn something new, or find some help. Our articles have something to keep everyone writing."
    />
    <SuawGridSection :max-columns-per-row="3">
      <SuawArticleCard v-for="article in recentArticles" :key="article.id" v-bind="article" />
    </SuawGridSection>
    <SuawDivider class="article-discovery-bottom-divider" />
    <SuawPageHeaderActions back-text="Back to Resources" :back-route-object="{ name: 'ResourceDiscovery' }" />
    <WriteWithUs />
  </SuawMainContent>
</template>

<style lang="scss" scoped>
.article-discovery-subheader {
  margin-bottom: 32px;
}
.article-discovery-bottom-divider {
  margin-top: 32px;
}
</style>
